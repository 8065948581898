* {
  box-sizing: border-box;
}

.main-layout {
    display: flex;
}

.left-layout {
    position: relative;
    height: 100vh;
    width: 16rem;
    background-color: aqua;
}

.right-layout {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.header-layout {
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    background-color:#5f9ea0;
}

.content-layout {
    height: 100vh;
    width: 100%;
    overflow: auto;
    display: flex;
    background-color:gainsboro;
}

.main2-layout {
    display: flex;
    flex-direction: column;
}

.header2-layout {
    position: relative;
    height: 12vh;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    justify-content: space-between;
    background-color: #063380;
    border-bottom: 1px solid #063380;
}

.content2-layout {
    position: relative;
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    background-color: whitesmoke;
}

.left2-layout {
    position: relative;
    height: 100vh;
    width: 16rem;
    background-color: white;
}

.right2-layout {
    position: relative;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    background-color: whitesmoke;
}

  .caret {
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
  }
  
  .caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
  }
  
  .caret-down::before {
    transform: rotate(90deg);  
  }
  
  .tree {
      padding: 20px;
  }

  .home {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }

  .home-logo {
    height: 10vmin;
    pointer-events: none;
    margin-bottom: 1.5vh;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .home-logo {
      animation: home-logo-float infinite 8s ease-in-out;
    }
  }
  
  @keyframes home-logo-float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px)
    }
    100% {
      transform: translateY(0px)
    }
  }

  .center-container { 
    display: flex;
    justify-content: center; /* horizontal */
    align-items: center;     /* vertical */
    width: 100%;
  }

  /* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #063380f1; 
  box-shadow: inset 0 0 5px grey; 
  border-radius: 50px;
}
 
/* Handle */
::-webkit-scrollbar-thumb:hover {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb {
  background: #555; 
  border-radius: 50px;
}

/**********************************/

.nav-icon {
  font-size: 1.8rem;
  cursor: pointer;
}

/**********************************/

.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1pxsolid #063380;
  position: absolute;
  top: 9vh;
  left: -110%;
  opacity: 1;
  transition: all 0.5s ease;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

@media screen and (max-width: 480px) {
  .nav-menu {
    top : 52px;
  }
}

.nav-menu.active {
  background: #063380;
  left: 0px;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 99;
}

/****************************************/

.nav-item {
  /* line-height: 40px;
  margin-right: 1rem; */
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

/*********************************************/

.nav-links {
  color: #fff;
  text-decoration: none;
  height: 100%;
  border-bottom: 3px solid transparent;
  padding: 1.5rem;
  width: 100%;
  display: table;
  cursor: pointer;
}

.nav-links:hover {
  color: orange;
}

.tittle{
  color: white;
}
.center{
  text-align: center;
}

input[type=text] {
  border: 2px solid;
  border-radius: 4px;
}

input[type=password] {
  border: 2px solid;
  border-radius: 4px;
}

.logo{
  height: 50px;
  margin-left: 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: black;
  background-color: #facd01;
  border: none;
  border-radius: 15px;
  box-shadow: 0 5px #999;
}

.button:hover {background-color: #ffcc29}

.button:active {
  background-color: #ffcc29;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.button2 {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: red;
  border: none;
  border-radius: 15px;
  box-shadow: 0 5px #999;
  margin-left: auto; 
  margin-right: 10%;
}

.button2:hover {background-color: red}

.button2:active {
  background-color: red;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

