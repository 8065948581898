.container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* horizontal */
    align-items: center;     /* vertical */
    width: 100%;
}

.result {
    margin-top: 10px;
    word-wrap: break-word;
    width: 300px;
}

.user {
    margin-left: auto; 
    margin-right: 10vw;
}

.hidden {
    opacity: 0.1;
}